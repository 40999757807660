import { useCallback } from 'react';
import { useAxios } from '../../hooks/useAxios';

export const useTransactionService = () => {
    const { get, patch, post, remove, isLoading } = useAxios();

    const getTransactionsByCompany = useCallback(
        (companyId, page) =>
            get(`bookkeeper/get/transactions/posted/${companyId}?page=${page}`),
        [get],
    );

    const getUnpostedTransactionsByCompany = useCallback(
        (companyId) => get(`bookkeeper/get/transactions/unposted/${companyId}`),
        [get],
    );

    const getTransactionsByChat = useCallback(
        (chatId) => get(`bookkeeper/get/transactions/chat/${chatId}`),
        [get],
    );

    const updatedTransaction = (transactionId, transaction) =>
        patch(
            `bookkeeper/${transactionId}/edit/transactions`,
            transaction,
            'Updated Successfully',
        );

    const confirmTransaction = (transactionIds) =>
        post(
            `bookkeeper/confirm/transactions`,
            { transaction_ids: transactionIds },
            '',
        );

    const postTransaction = (transactionId) =>
        post(
            `bookkeeper/transactions/${transactionId}/post/`,
            {},
            'Transaction Imported Successfully',
        );

    const editPostTransaction = (transactionId, item) =>
        patch(
            `bookkeeper/transactions/${transactionId}/edit_and_post/`,
            item,
            'Transaction Edited and Posted Successfully',
        );

    const createNewTransaction = (companyId, payload) =>
        post(
            `bookkeeper/${companyId}/create/transactions`,
            { ...payload },
            'Transaction Created Successfully!',
        );

    //Partially edit an existing transaction or its items for a company.
    //Include in req.body journal_entries: [{account: account.id, amount: 10, entry_type: Debit}, {account:account.id, amount: 10, entry_type: Credit}]
    const updatedPartialTransaction = (transactionId, transaction) =>
        patch(
            `bookkeeper/${transactionId}/edit/transaction/accountant_mode/`,
            transaction,
            'Updated Successfully',
        );

    const updateTransaction = (transactionId, transaction) =>
        patch(
            `bookkeeper/transactions/${transactionId}/edit_and_post/`,
            transaction,
            null,
        );

    const undoTransaction = (transactionId, transaction) =>
        post(
            `bookkeeper/unpost/transaction/${transactionId}`,
            transaction,
            'Transaction unposted successfully',
        );

    const deleteTransaction = (transactionId) =>
        remove(
            `bookkeeper/delete/transaction/${transactionId}`,
            {},
            'Transaction deleted successfully',
        );

    const getPendingTransactionsForReview = useCallback(
        (companyId) =>
            get(`bookkeeper/get/transactions/pendingreview/${companyId}`),
        [get],
    );

    const processReceipts = useCallback(
        (transactionType, bookByEachItem, companyId, files) => {
            // Create a new FormData object
            const formData = new FormData();

            // Append the regular fields to FormData
            formData.append('transaction_type', transactionType);
            formData.append('book_by_each_item', bookByEachItem);
            formData.append('company_id', companyId);

            // Append each file to FormData
            files.forEach((file) => {
                formData.append('file', file); // Assuming `files` is an array of `File` objects or Blob
            });

            // Send the FormData via POST request
            return post(
                `bookkeeper/process-receipt/`,
                formData, // Use FormData instead of the payload
                'Receipt processed successfully!',
            );
        },
        [post],
    );

    return {
        isLoading,
        undoTransaction,
        deleteTransaction,
        updatedTransaction,
        confirmTransaction,
        getTransactionsByChat,
        getTransactionsByCompany,
        updatedPartialTransaction,
        getPendingTransactionsForReview,
        createNewTransaction,
        processReceipts,
        updateTransaction,
        getUnpostedTransactionsByCompany,
        postTransaction,
        editPostTransaction,
    };
};
